






































































import { Invoice } from '@app/models';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import FilterOverview from '../components/6-other/filter-overview.vue';
import ResearchTargetTabs from '../components/research/research-target-tabs.vue';
import { ResearchModule } from '../store/modules/research-module';
import { ResultModule } from '../store/modules/result-module';

@Component({
  components: { FilterOverview, ResearchTargetTabs },
})
export default class Dashboard extends Vue {
  public research = getModule(ResearchModule);
  public result = getModule(ResultModule);

  public get invoice() {
    return this.$company.getInvoice(this.research.current?.id);
  }

  public get paid() {
    return !this.research.current?.structure || !!this.invoice?.paid;
  }

  public get targets() {
    return this.$targetTabs.filter(t =>
      this.research.current?.target.some(c => c.startsWith(t))
    );
  }

  async created() {
    const { id } = this.$route.params;
    await this.research.bind();
    await this.result.bind(id);

    const targets = this.research.targets || [];
    if (targets.indexOf(this.result.target as any) < 0)
      await this.result.setTarget(targets[0] || '');
  }

  public async downloadInvoice(invoice: Invoice) {
    const path = `/company/${invoice.company}/invoice/${invoice.id}.pdf`;
    const downloadUrl = await this.$firebase.file(path).getDownloadURL();

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.click();
  }
}
