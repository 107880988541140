





















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ResultModule } from '../../store/modules/result-module';
import { StoredFilter } from '../../models/stored-filter';

@Component
export default class FilterOverview extends Vue {
  private result = getModule(ResultModule);

  public name(filter: StoredFilter, option: StoredFilter['options'][number]) {
    const name = option.name || parseInt('' + option.value, 10) + 1;
    return `${filter.category} / ${name}`;
  }

  public remove(event: Event) {
    const target = event.target as HTMLBceChipElement;
    const id = target.value as string;
    const index = target.dataset.index!.includes(',')
      ? target.dataset.index!
      : parseInt(target.dataset.index || '', 10);

    this.result.filterRemove({ id, index });
  }

  public toggle(event: Event) {
    const target = event.target as HTMLBceChipElement;
    const active = target.checked;
    const id = target.value as string;
    const index = target.dataset.index!;

    this.result.filterToggle({
      active,
      id,
      index: index.indexOf(',') > 0 ? undefined : parseInt(index, 10),
    });
  }
}
